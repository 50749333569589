import React, { useState, useEffect } from "react";
import {
  Table,
  Label,
  Input,
  Form,
  Dropdown,
  Grid,
  Tab,
  Modal,
  Button,
  Popup,
  Icon,
  Segment,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { colorName } from "./colorname";
import {
  getAllDocument,
  deleteDocument,
  restoreDocument,
  getDocumentById,
  getStepsByDocumentId,
} from "../apiCall";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import {
  setDocumentCollection,
  setProcedureDetails,
  setSelectedTemplate,
  updateDefaultStep,
  addStep,
  saveIsReviewer,
  setIsFromFeedback,
  setDocumentId,
  resetDocumentMedia,
  mediafiletorender,
  savePrevData,
} from "./../../redux/actions/index";
import KeycloakUserService from "../login/KeyCloakService";
import { appInsights } from "../utils/ustils";

const DocumentList = (props) => {
  const [documents, setDocuments] = useState([]);
  const [documentsByStatus, setDocumentsByStatus] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [dataId, setDataId] = useState("");
  const [isRestoreAction, setIsRestoreAction] = useState(false);
  const userEmail = KeycloakUserService.getEmail();
  const [menuSelected, setMenuOption] = useState("My Documents");
  const [documentFiltered, setDocumentFiltered] = useState([]);
  const [sorting, setSorting] = useState({
    key: "modifiedOn",
    ascending: false,
  });
  const [docArrayPopulated, setDocArrayPopulated] = useState(false);
  const [statusOptionSelected, setStatusOptionSelected] = useState("all");
  // state to remove multiple api calls
  const [loader, setLoader] = useState(false);
  const getIconEnable = (data) => {
    return (
      !props.isReviewer && data.status.toUpperCase() === "AWAITING_REVIEW"
      // ||
      // ((data.status.toUpperCase() === "IN_PROGRESS" ||
      //   data.status.toUpperCase() === "DRAFT") &&
      //   data.modifiedBy !== userEmail)
    );
  };

  const handleRestoreOnClick = (data) => {
    setDataId(data.documentId);
    setIsRestoreAction(true);
    setPopupVisible(true);
  };
  
  const handleRestore = async () => {
    await restoreDocument(dataId);
    appInsights.trackEvent({
      name: "RestoreDocument",
      properties: {
        documentId: dataId,
        username: KeycloakUserService.getUsername(),
      },
    });
    getDocuments();
    setPopupVisible(false);
    setDataId("");
    setIsRestoreAction(false);
  };

  const feedbackIconStatus = (data) => {
    return (
      <div style={{ position: "relative" }}>
        <i
          className={
            data?.status?.toUpperCase() === "APPROVED" ||
            data?.feedbackComments?.length
              ? "comment icon action-icon"
              : "comment icon disabled"
          }
          id="penIcon"
          onClick={
            data?.status.toUpperCase() === "APPROVED" ||
            data?.feedbackComments?.length > 0
              ? () => handleonClickFeedback(data)
              : null
          }
        >
          {data?.feedbackComments?.length > 0 && (
            <span className="feedback-counter">
              <strong>{data?.feedbackComments?.length}</strong>
            </span>
          )}
        </i>
      </div>
    );
  };

  const handleonClickFeedback = (data) => {
    props.setIsFromFeedback(true);
    props.history.push("/Feedbacklist");
    props.setDocumentId(data.documentId);
  };
  useEffect(() => {
    props.statusOptionSelectedSummmary &&
      handleStatusChange(null, props.statusOptionSelectedSummmary);
  }, [props.statusOptionSelectedSummmary]);

  const handleDeleteOnclick = (data) => {
    setDataId(data.documentId);
    setIsRestoreAction(false);
    setPopupVisible(true);
  };
  
  // Display either delete or restore icon based on document deleted status
  const actionIconStatus = (data) => {
    if (data.deleted) {
      return (
        <i
          className="undo icon action-icon"
          id="restoreIcon"
          onClick={() => handleRestoreOnClick(data)}
        ></i>
      );
    } else {
      return (
        <i
          className={
            getIconEnable(data)
              ? "trash alternate icon disabled"
              : "trash alternate icon action-icon"
          }
          id="penIcon"
          onClick={getIconEnable(data) ? null : () => handleDeleteOnclick(data)}
        ></i>
      );
    }
  };
  
  const editIconStatus = (data) => {
    return (
      <i
        className={
          getIconEnable(data) || data.deleted
            ? "pencil alternate icon disabled"
            : "pencil alternate icon action-icon"
        }
        id="penIcon"
        onClick={getIconEnable(data) || data.deleted ? null : () => handleEditSolution(data)}
      >
        {" "}
      </i>
    );
  };
  const panes = [
    {
      menuItem: "All Documents",
      render: () => displayTab("All Documents"),
    },
    {
      menuItem: "My Documents",
      render: () => displayTab("My Documents"),
    },
  ];
  const renderTabContent = () => {
    return (
      <Tab
        menu={{ color: "blue", secondary: true, pointing: true }}
        panes={panes}
      />
    );
  };

  const updateSummary = (documentCollection, menuOption = "All Documents") => {
    let inProgressDocCount = 0,
      approvedCount = 0,
      reviewCount = 0,
      feedbackDocCount = 0,
      draftCount = 0,
      deletedCount = 0; // Added counter for deleted documents
      
    for (let i = 0; i < documentCollection.length; i++) {
      const doc = documentCollection[i];
      
      if (menuOption === "All Documents") {
        // First check if document is deleted
        if (doc.deleted) {
          deletedCount++;
        } else {
          // Only count non-deleted documents in their status categories
          if (doc.status === "APPROVED") approvedCount++;
          if (doc.status === "IN_PROGRESS") inProgressDocCount++;
          if (doc.status === "DRAFT") draftCount++;
          if (doc.status === "AWAITING_REVIEW") reviewCount++;
        }
        if (doc.feedbackComments?.length > 0) feedbackDocCount++;
      } else if (
        menuOption === "My Documents" &&
        (doc.modifiedBy === userEmail || doc.createdBy === userEmail)
      ) {
        // First check if document is deleted
        if (doc.deleted) {
          deletedCount++;
        } else {
          // Only count non-deleted documents in their status categories
          if (doc.status === "APPROVED") approvedCount++;
          if (doc.status === "IN_PROGRESS") inProgressDocCount++;
          if (doc.status === "DRAFT") draftCount++;
          if (doc.status === "AWAITING_REVIEW") reviewCount++;
        }
        if (doc.feedbackComments?.length > 0) feedbackDocCount++;
      }
    }
    
    const docCounts = {
      approvedCount,
      reviewCount,
      inProgressDocCount,
      feedbackDocCount,
      draftCount,
      deletedCount // Added to object sent to parent component
    };
  
    props.setCount(docCounts);
  };

  useEffect(() => {
    sortDocuments(sorting.key, sorting.ascending);
    // updateSummary(documentsByStatus)
  }, [documentsByStatus]);

  const sortDocuments = (key, ascending) => {
    const documentsCopy = documentsByStatus;

    const sortedDocuments = documentsCopy.sort((a, b) => {
      return a[key].localeCompare(b[key]);
    });
    setDocumentsByStatus(
      ascending ? sortedDocuments : sortedDocuments.reverse()
    );
  };

  const applySorting = (key, ascending) => {
    setSorting({ key: key, ascending: ascending });
    sortDocuments(key, ascending);
  };

  // display my document and all document
  const displayTab = (menuOption) => {
    setMenuOption(menuOption);
    return (
      <div className="tableWrap">
        <Table
          celled
          unstackable
          style={{ border: "none" }}
          className="table-wrapper"
        >
          <Table.Header>
            <Table.Row style={{ position: "sticky", top: "0", zIndex: "1" }}>
              <Table.HeaderCell
                style={{ background: "#5CAAF0" }}
                onClick={() =>
                  applySorting("procedureTitle", !sorting.ascending)
                }
              >
                Document Name
                {sorting.ascending && sorting.key === "procedureTitle" ? (
                  <Icon name="sort up" />
                ) : (
                  <Icon name="sort down" />
                )}
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ background: "#5CAAF0" }}
                className="table-header"
                onClick={() => applySorting("createdBy", !sorting.ascending)}
              >
                Created By
                {sorting.ascending && sorting.key === "createdBy" ? (
                  <Icon name="sort up" />
                ) : (
                  <Icon name="sort down" />
                )}
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ background: "#5CAAF0" }}
                className="table-header"
                onClick={() => applySorting("createdOn", !sorting.ascending)}
              >
                Created On
                {sorting.ascending && sorting.key === "createdOn" ? (
                  <Icon name="sort up" />
                ) : (
                  <Icon name="sort down" />
                )}
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ background: "#5CAAF0" }}
                className="table-header"
                onClick={() => applySorting("modifiedBy", !sorting.ascending)}
              >
                Last Modified By
                {sorting.ascending && sorting.key === "modifiedBy" ? (
                  <Icon name="sort up" />
                ) : (
                  <Icon name="sort down" />
                )}
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ background: "#5CAAF0" }}
                className="table-header"
                onClick={() => applySorting("modifiedOn", !sorting.ascending)}
              >
                Last Modified on
                {sorting.ascending && sorting.key === "modifiedOn" ? (
                  <Icon name="sort up" />
                ) : (
                  <Icon name="sort down" />
                )}
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ background: "#5CAAF0" }}
                className="table-header"
                onClick={() => applySorting("status", !sorting.ascending)}
              >
                Status
                {sorting.ascending && sorting.key === "status" ? (
                  <Icon name="sort up" />
                ) : (
                  <Icon name="sort down" />
                )}
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ background: "#5CAAF0" }}
                className="table-header"
              >
                Actions
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{displayData(menuOption)}</Table.Body>
          {popupVisible && (
            <Modal
              className="modal-upload-delete"
              open={popupVisible}
              onClose={() => setPopupVisible(false)}
            >
              <div className="modal-heading-delete">
                <div>
                  <h3>{isRestoreAction ? "Confirm Restore" : "Confirm Delete"}</h3>
                  <p>
                    {isRestoreAction
                      ? "Are you sure you want to restore the document?"
                      : "Are you sure you want to delete the document?"}
                  </p>
                </div>

                <button
                  style={{ marginRight: "1rem" }}
                  onClick={() => setPopupVisible(false)}
                >
                  X
                </button>
              </div>
              <div className="modal-action-delete">
                <Button
                  className="modal-button-option-del1"
                  onClick={() => setPopupVisible(false)}
                >
                  No
                </Button>
                {isRestoreAction ? (
                  <Button color="green" onClick={handleRestore}>
                    Restore
                  </Button>
                ) : (
                  <>
                    <Button color="youtube" onClick={() => handleDelete(false)}>
                      Delete
                    </Button>
                    <Button color="red" onClick={() => handleDelete(true)}>
                      Delete Permanently
                    </Button>
                  </>
                )}
              </div>
            </Modal>
          )}
        </Table>
      </div>
    );
  };

  /* This functions gets all the solutions from the db */
  const getDocuments = () => {
    getAllDocument()
      .then((response) => {
        setDocArrayPopulated(true);
        const documentCollection = response.data.data.documents;
        props.setDocumentCollection(documentCollection);
        setDocuments(documentCollection);
        setDocumentsByStatus(documentCollection);
        setDocumentFiltered(documentCollection);
        updateSummary(documentCollection);
      })
      .catch((error) => {
        // KeycloakUserService.doLogout();
      });
  };

  useEffect(() => {
    props.setIsFromFeedback(false);
    getDocuments();
    props.resetDocumentMedia(true);
  }, [props.isReviewer]);

  const handleDelete = async (deletePermanently) => {
    await deleteDocument(dataId, deletePermanently);
    appInsights.trackEvent({
      name: "RemoveDocument",
      properties: {
        documentId: dataId,
        username: KeycloakUserService.getUsername(),
      },
    });
    getDocuments();
    setPopupVisible(false);
    setDataId("");
  };

  const handleEditSolution = async (data) => {
    console.log("handleEditSolution");
    setLoader(true);
    await getStoreData(true, data);
  };

  const getStoreData = async (isEdit, data) => {
    console.log("getstoreData");

    console.log("if condition ");

    await getDocumentById(data.documentId).then((response) => {
      props.setSelectedTemplate(response.data.data.document.documentType);

      response.data.data.document.media.forEach((file) => {
        if (file.fileName) {
          const ext = file.fileName.split(".").pop();
          file.extention = ext;
        }
      });
      const data = {
        procedureTitle: response.data.data.document.procedureTitle,
        procedureDescription: response.data.data.document.procedureDescription,
        status: response.data.data.document.status,
        category: response.data.data.document.category,
        documentId: response.data.data.document.documentId,
        media: response.data.data.document.media,
        lastApprovedVersion: response.data.data.document.lastApprovedVersion,
        lastApprovedSteps: response.data.data.document.lastApprovedSteps,
        changeLog: response.data.data.document.changeLog,
        
      };
      props.setProcedureDetails(data);
      props.savePrevData(data);
      props.mediafiletorender(
        response.data.data.document.media
          ? response.data.data.document.media
          : []
      );
    });

    await getStepsByDocumentId(data.documentId).then((response) => {
      const data = response.data.data.steps;
      data.forEach((step, index) => {
        if (index === 0) {
          props.updateDefaultStep(step);
        } else {
          props.addStep(step);
        }
      });

      if (isEdit) {
        setTimeout(() => {
          props.history.push(`/CreateInstructionsForm`);
        }, 1000);
      } else {
        const newTabUrl = window.location.origin + `/ReadonlyPreview`;
        setTimeout(() => {
          window.open(newTabUrl, "_blank");
        }, 1000);
        setLoader(false);
      }
    });
  };

  const handleDocumentClick = (document) => {
    appInsights.trackEvent({
      name: "ViewDocument",
      properties: {
        documentId: document.documentId,
        title: document.procedureTitle,
        username: KeycloakUserService.getUsername(),
      },
    });
    setLoader(true);
    getStoreData(false, document);
  };

  const getDocumentTitle = (document) => {
    let docTitle = document.procedureTitle;
    // if (document.procedureTitle.length > 20) {
    //   docTitle = document.procedureTitle.substring(0, 20) + "...";
    // } else {
    //   docTitle = document.procedureTitle;
    // }
    return (
      <Popup
        style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
        trigger={
          <a
            style={{ textDecoration: "underline" }}
            onClick={() => handleDocumentClick(document)}
          >
            {docTitle}
          </a>
        }
        position="left center"
      >
        {document.procedureTitle}
      </Popup>
    );
  };
  const redirectToOutlook = (email) => {
    window.open(
      `https://outlook.office365.com/mail/deeplink/compose?to=${email}`,
      "_blank"
    );
  };
  const displayData = (menuOption) => {
    updateSummary(documents, menuOption);
    return documentsByStatus.length ? (
      documentsByStatus
        ?.filter((info) => {
          return (
            ((menuOption === "My Documents" &&
              (info.modifiedBy === userEmail ||
                info.createdBy === userEmail)) ||
              menuOption === "All Documents") &&
            (props.isReviewer ? info.status !== "DRAFT" : true)
          );
        })
        .map((info) => {
          return (
            info.procedureTitle.length > 0 && (
              <Table.Row key={info.documentId}>
                <Table.Cell className="table-content">
                  {getDocumentTitle(info)}
                </Table.Cell>
                <Table.Cell className="table-header">
                  {info.createdBy}
                </Table.Cell>
                <Table.Cell className="table-header">
                  {moment(info.createdOn).format("MM/DD/YYYY")}
                </Table.Cell>
                <Table.Cell
                  className="table-header"
                  onClick={() => {
                    redirectToOutlook(info.modifiedBy);
                  }}
                >
                  <a>{info.modifiedBy}</a>
                </Table.Cell>
                <Table.Cell className="table-header">
                  {moment(info.modifiedOn).format("MM/DD/YYYY")}
                </Table.Cell>
                <Table.Cell className={colorName(info.deleted ? "DELETED" : info.status)}>
                  <Label horizontal>
                    {info.deleted ? "DELETED" : info.status.toUpperCase().replace("_", " ")}
                  </Label>
                </Table.Cell>
                <Table.Cell className="align-center">
                  <Popup
                    style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                    trigger={feedbackIconStatus(info)}
                    position="top center"
                  >
                    Feedback
                  </Popup>
                  <Popup
                    style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                    trigger={editIconStatus(info)}
                    position="top center"
                  >
                    Edit Document
                  </Popup>
                  <Popup
                    style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                    trigger={actionIconStatus(info)}
                    position="top center"
                  >
                    {info.deleted ? "Restore Document" : "Delete Document"}
                  </Popup>
                </Table.Cell>
              </Table.Row>
            )
          );
        })
    ) : (
      <div className="centered-content">
        <div className="No-data">
          {docArrayPopulated ? (
            <h2>No Documents</h2>
          ) : (
            <h2>Fetching data...</h2>
          )}
        </div>
      </div>
    );
  };

  const handleStatusChange = (event, data) => {
    setStatusOptionSelected(data.value);
    props.setStatusFromDropDown(data);
    let statusKey = "";
    
    // If filter is for "open feedback"
    if (
      props.isReviewer
        ? data.value.toLowerCase() === statusOptions[4].value.toLowerCase()
        : data.value.toLowerCase() === statusOptions[5].value.toLowerCase()
    ) {
      let filteredArray = documents.filter(function (el) {
        return el?.feedbackComments?.length > 0;
      });
  
      setDocumentFiltered(filteredArray);
      setDocumentsByStatus(filteredArray);
    } else {
      // For "all" documents
      if (data.value.toLowerCase() === statusOptions[0].value.toLowerCase()) {
        setDocumentsByStatus(documents);
        setDocumentFiltered(documents);
      } 
      // For "deleted" documents
      else if (data.value.toLowerCase() === "deleted") {
        let filteredArray = documents.filter(function (el) {
          return el.deleted === true;
        });
        
        setDocumentFiltered(filteredArray);
        setDocumentsByStatus(filteredArray);
      } 
      // For other status filters
      else {
        if (data.value.toLowerCase() === statusOptions[1].value.toLowerCase()) {
          statusKey = statusOptions[1].key;
        }
        if (data.value.toLowerCase() === statusOptions[2].value.toLowerCase() && 
            data.value.toLowerCase() !== "deleted") {
          statusKey = statusOptions[2].key;
        }
        if (data.value.toLowerCase() === statusOptions[3].value.toLowerCase()) {
          statusKey = statusOptions[3].key;
        }
        if (
          !props.isReviewer &&
          data.value.toLowerCase() === statusOptions[4].value.toLowerCase()
        ) {
          statusKey = statusOptions[4].key;
        }
  
        // Filter by status and ensure not deleted
        let filteredArray = documents.filter(function (el) {
          return el.status.toLowerCase() === statusKey.toLowerCase() && !el.deleted;
        });
  
        setDocumentFiltered(filteredArray);
        setDocumentsByStatus(filteredArray);
      }
    }
  };

  const statusOptions = !props.isReviewer
    ? [
        {
          key: "all",
          text: "ALL",
          value: "all",
        },
        {
          key: "APPROVED",
          text: "APPROVED",
          value: "approved",
        },
        {
          key: "DELTED",
          text: "DELETED",
          value: "deleted",
        },
        {
          key: "AWAITING_REVIEW",
          text: "AWAITING REVIEW",
          value: "awaiting-review",
        },
        {
          key: "IN_PROGRESS",
          text: "IN PROGRESS",
          value: "in-progress",
        },
        {
          key: "DRAFT",
          text: "DRAFT",
          value: "draft",
        },

        {
          key: "OPEN FEEDBACK",
          text: "OPEN FEEDBACK",
          value: "open feedback",
        },
      ]
    : [
        {
          key: "all",
          text: "ALL",
          value: "all",
        },
        {
          key: "APPROVED",
          text: "APPROVED",
          value: "approved",
        },
        {
          key: "AWAITING_REVIEW",
          text: "AWAITING REVIEW",
          value: "awaiting-review",
        },
        {
          key: "IN_PROGRESS",
          text: "IN PROGRESS",
          value: "in-progress",
        },

        {
          key: "OPEN FEEDBACK",
          text: "OPEN FEEDBACK",
          value: "open feedback",
        },
      ];

  const onFilter = (key) => {
    var filterdata = documentFiltered
      .filter((x) => x.procedureTitle.toLowerCase().includes(key.toLowerCase()))
      .filter((x) => x !== undefined);
    console.log(filterdata.length, "filterdata");
    setDocumentsByStatus(filterdata);
  };

  return (
    <div className="session-summary documentlist document-table">
      {loader ? (
        <div className="fullscreen-overlay">
         <Dimmer active inverted>
        <Loader size='large'>Loading</Loader>
      </Dimmer>
        </div>
      ) : (
        <div className="search-dropdown">
          <Form>
            <Grid padded>
              <Grid.Row stretched>
                <Grid.Column className="searchsolution" width={13}>
                  <Form.Field>
                    <Popup
                      style={{
                        background: "rgb(242 247 251)",
                        fontSize: "10px",
                      }}
                      trigger={
                        <Input
                          icon="search"
                          iconPosition="left"
                          style={{ height: "30px", fontSize: "10px" }}
                          placeholder={
                            menuSelected === "My Documents"
                              ? "Search My Documents"
                              : "Search All Documents"
                          }
                          onChange={(e) => {
                            onFilter(e.target.value);
                          }}
                        />
                      }
                      position="top left"
                    >
                      Search by document name
                    </Popup>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column className="searchsolutionstatus">
                  <Dropdown
                    className="statusdropdown"
                    style={{ fontSize: "8px", height: "30px " }}
                    selection
                    value={statusOptionSelected}
                    options={statusOptions}
                    onChange={handleStatusChange}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </div>
      )}
      <div className="main-header">{renderTabContent()}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    documentList: state.documentList,
    isReviewer: state.isReviewer,
    isFromFeedback: state.isFromFeedback,
    documentId: state.documentId,
    prevDocData: state.prevDocData,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setDocumentCollection,
    setProcedureDetails,
    setSelectedTemplate,
    addStep,
    updateDefaultStep,
    saveIsReviewer,
    setIsFromFeedback,
    setDocumentId,
    resetDocumentMedia,
    mediafiletorender,
    savePrevData,
  })(DocumentList)
);