import React, { useCallback, useEffect, useState } from "react";
import { Container } from "semantic-ui-react";
import { ClientRoles } from "../../const/ClientRoles";
import KeycloakUserService from "../login/KeyCloakService";
import DocumentList from "./DocumentList";
import LexxDashboardCard from "./LexxDashboardCard";
import {
  saveIsReviewer,
  setFormEdit,
  setStepOrdered,
  setStepEdit,
  passMediaURL,
  mediafiletorender,
  removeNewStepIds,
  setStepFormEdit,
  showPopupAction,
  resetPrevData,changeIsThere,changeInStepIsThere,
  aiButtonon
} from "./../../redux/actions/index";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import SummaryOfDoc from "./SummaryOfDoc";

/* This is the component to display the Dashboard (main page after login) */
const LexxDashboard = (props) => {
  /* This component calls 2 components 
        1. Dashboard Card - for buttons
        2. Carousel - for session and task carousel
    */
  const [userRoles, setUserRoles] = useState(
    KeycloakUserService.getClientRoles()
  );
  const  [statusOptionSelectedSummmary , setStatusOptionSelectedSummmary] = useState("");

  const [feedbackDocCount, setFeedbackDocCount] = useState(0);
  const [awaitingReviewDocCount, setAwaitingReviewDocCount] = useState(0);
  const [inProgressDocCount, setInProgressDocCount] = useState(0);
  const [approvedDocCount, setApprovedDocCount] = useState(0);
  const [draftCount,setDraftCount] = useState(0);
  const [statusFromDropDown , setStatusFromDropDown] = useState("");
  const [deletedDocCount , setDeletedDocCount] = useState(0);
  const setCount = (data)=>{
    console.log("data",data)
    setApprovedDocCount(data.approvedCount);
    setAwaitingReviewDocCount(data.reviewCount);
    setInProgressDocCount(data.inProgressDocCount);
    setFeedbackDocCount(data.feedbackDocCount);
    setDraftCount(data.draftCount);
    setDeletedDocCount(data.deletedCount);
  }

  useEffect(() => {
    // const isReviewer = userRoles.filter(
    //   (role) => role === ClientRoles.reviewer
    // );
    // if (isReviewer.length > 0) {
    //   props.saveIsReviewer(true);
    // } else {
    //   props.saveIsReviewer(false);
    // }
    props.setFormEdit(false);
    props.setStepFormEdit(false);
    props.setStepOrdered(false);
    props.setStepEdit(false);
    props.passMediaURL(null);
    props.mediafiletorender([]);
    props.removeNewStepIds([]);
    props.showPopupAction(false);
    props.resetPrevData();
    props.aiButtonon(false);
      props.changeIsThere(false);
  props.changeInStepIsThere("");
  }, []);

  useEffect(() => {console.log(deletedDocCount)},[deletedDocCount]);
const setStatusOptionSelectedSummmaryFunc = (data)=>{
  setStatusOptionSelectedSummmary(data)
}

  return (
    <>
      {/* <SidebarComp /> */}
      <div className="content-container home-container">
        <Container className="dashboard-container">
          {/* <h2>Dashboard</h2>
          <hr style={{ opacity: "50%" }} /> */}
          <LexxDashboardCard />
          <SummaryOfDoc  setStatusOptionSelectedSummmary={setStatusOptionSelectedSummmaryFunc} feedbackDocCount={feedbackDocCount} awaitingReviewDocCount={awaitingReviewDocCount} inProgressDocCount={inProgressDocCount} deletedDocCount={deletedDocCount} approvedDocCount={approvedDocCount} draftCount={draftCount} statusFromDropDown={statusFromDropDown} />
          <DocumentList statusOptionSelectedSummmary={statusOptionSelectedSummmary} setCount={setCount} setStatusFromDropDown={setStatusFromDropDown} />
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isReviewer: state.isReviewer,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    saveIsReviewer,
    setFormEdit,
    setStepOrdered,
    setStepEdit,
    passMediaURL,
    mediafiletorender,
    removeNewStepIds,
    setStepFormEdit,
    showPopupAction,
    resetPrevData,changeIsThere,changeInStepIsThere,
    aiButtonon
  })(LexxDashboard)
);
