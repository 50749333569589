import React, { useEffect, useState } from 'react'
import {
  Card,
  Grid,
  Icon,
} from "semantic-ui-react";
import summaryicon from "../../assets/images/summaryicon.png"
import { useSelector } from 'react-redux';

const SummaryOfDoc = ({ setStatusOptionSelectedSummmary, feedbackDocCount, awaitingReviewDocCount, deletedDocCount,inProgressDocCount, approvedDocCount, draftCount, statusFromDropDown }) => {
  const isReviewer = useSelector((state) => state.isReviewer);
  const [isSelected, setIsSelected] = useState(false);
  const handleClick = (status) => {
    setStatusOptionSelectedSummmary(status);
    setIsSelected(status.value);
    // Your onClick logic[]
  };
  useEffect(() => {
    setStatusOptionSelectedSummmary(statusFromDropDown);
    setIsSelected(statusFromDropDown.value);
  }, [statusFromDropDown])

  useEffect(() => {
    console.log("isSelected", deletedDocCount)
  }, []);
  return (

    <div className='summaryOfDoc'>
      <Card.Group className='card-group'>
        <Card className={`card-tile ${isSelected === "open feedback" ? 'selected' : ''}`}
          onClick={() => handleClick({ value: 'open feedback' })}
        >
          <Card.Content>
            <Card.Header className='tile-heading'>OPEN FEEDBACK</Card.Header>
            <p className='tile-number'>{feedbackDocCount}</p>
          </Card.Content>
          <Card.Content extra>
            <div className="icon-container gray-status-tiles "><img className="summaryicon" src={summaryicon} /></div>
          </Card.Content>
        </Card>
        <Card className={`card-tile ${isSelected === "awaiting-review" ? 'selected' : ''}`}
          onClick={() => handleClick({ value: 'awaiting-review' })}>
          <Card.Content>
            <Card.Header className='tile-heading'>AWAITING REVIEW</Card.Header>
            <p className='tile-number'>{awaitingReviewDocCount} </p>
          </Card.Content>
          <Card.Content extra>

            <div className="icon-container yellow-status-tiles "><img className="summaryicon" src={summaryicon} /></div>

          </Card.Content>
        </Card>
        <Card className={`card-tile ${isSelected === 'in-progress' ? 'selected' : ''}`}
          onClick={() => handleClick({ value: 'in-progress' })}
        >
          <Card.Content>
            <Card.Header className='tile-heading'>IN PROGRESS</Card.Header>
            <p className='tile-number'>{inProgressDocCount} </p>
          </Card.Content>
          <Card.Content extra>
            <div className="icon-container blue-status-tiles-inprogress "><img className="summaryicon" src={summaryicon} /></div>
          </Card.Content>
        </Card>
        {!isReviewer && <Card className={`card-tile ${isSelected === 'draft' ? 'selected' : ''}`}
          onClick={() => handleClick({ value: 'draft' })}
        >
          <Card.Content>
            <Card.Header className='tile-heading'>DRAFT</Card.Header>
            <p className='tile-number'>{draftCount} </p>
          </Card.Content>
          <Card.Content extra>
            <div className="icon-container blue-status-tiles "><img className="summaryicon" src={summaryicon} /></div>
          </Card.Content>
        </Card>}
        <Card className={`card-tile ${isSelected === "approved" ? 'selected' : ''}`}
          onClick={() => handleClick({ value: 'approved' })}
        >
          <Card.Content>
            <Card.Header className='tile-heading'>APPROVED</Card.Header>
            <p className='tile-number'>{approvedDocCount}</p>
          </Card.Content>
          <Card.Content extra>
            <div className="icon-container green-status-tiles "><img className="summaryicon" src={summaryicon} /></div>
          </Card.Content>
        </Card>
        <Card className={`card-tile ${isSelected === "deleted" ? 'selected' : ''}`}
          onClick={() => handleClick({ value: 'deleted' })}
        >
          <Card.Content>
            <Card.Header className='tile-heading'>DELETED</Card.Header>
            <p className='tile-number'>{deletedDocCount}</p>
          </Card.Content>
          <Card.Content extra>
            <div className="icon-container red-status-tiles"><img className="summaryicon" src={summaryicon} /></div>
          </Card.Content>
        </Card>

      </Card.Group>
    </div>
  )
}

export default SummaryOfDoc