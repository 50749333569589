function colorName(solution_status) {
  if (solution_status.toUpperCase() === "DRAFT") {
    return "draft status-label";
  } else if (solution_status.toUpperCase() === "AWAITING_REVIEW" ) {
    return "awaiting-review status-label";
  } else if ( solution_status.toUpperCase() === "IN_PROGRESS" ) {
    return "review-inprogress status-label";
  } else if (solution_status.toUpperCase() === "DELETED") {
    return "deleted status-label";
  }
  
  else {
    return "approved status-label";
  }
}


export { colorName };