import React, { useEffect, useState } from "react";
import { Button, Card, Image, Modal, Progress, Dropdown, Form } from "semantic-ui-react";
import axios from "axios";
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaHourglassHalf,
  FaCloudUploadAlt,
  FaTrash,
} from "react-icons/fa";
import Technician from "../../assets/images/technician.png";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  setSelectedTemplate,
  setProcedureDetails,
  resetProcedureDetails,
  resetStepDetails,
  saveIsReviewer,
  setNewDocument,
} from "./../../redux/actions";

const LexxDashboardCard = (props) => {
  const templateOptions = [
    {
      key: "setupInstruction",
      value: "Setup Instruction",
    },
    {
      key: "operatingProcedure",
      value: "Operating Procedure",
    },
  ];
  
  // Document type options for upload
  const documentTypeOptions = [
    { key: 'setup', text: 'SETUP INSTRUCTION', value: 'SETUP INSTRUCTION' },
    { key: 'procedure', text: 'PROCEDURE DESCRIPTION', value: 'PROCEDURE DESCRIPTION' },
  ];
  
  // Equipment options for upload
  const equipmentOptions = [
    { key: "adcp_dvl", text: "ADCP/DVL", value: "ADCP/DVL" },
    { key: "admin", text: "Admin", value: "Admin" },
    { key: "camera", text: "Camera", value: "Camera" },
    { key: "comm", text: "Comm", value: "Comm" },
    { key: "drone", text: "Drone", value: "Drone" },
    { key: "environmental", text: "Environmental", value: "Environmental" },
    { key: "geophysical", text: "Geophysical", value: "Geophysical" },
    { key: "gis", text: "GIS", value: "GIS" },
    { key: "gnss", text: "GNSS", value: "GNSS" },
    { key: "ins_mru", text: "INS/MRU", value: "INS/MRU" },
    { key: "mbes", text: "MBES", value: "MBES" },
    { key: "other_mapping_sensor", text: "Other Mapping Sensor", value: "Other Mapping Sensor" },
    { key: "sbes_sbp", text: "SBES/SBP", value: "SBES/SBP" },
    { key: "software", text: "Software", value: "Software" },
    { key: "usbl_lbl", text: "USBL/LBL", value: "USBL/LBL" },
    { key: "usv", text: "USV", value: "USV" },
    { key: "other", text: "Other", value: "Other" },
];

  
  // States for modals
  const [uploadPopupVisible, setUploadPopupVisible] = useState(false);
  const [templatePopupVisible, setTemplatePopupVisible] = useState(false);
  // Other states
  const [template, setTemplate] = useState(templateOptions[0].key);

  // Upload Modal States
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [progressStatus, setProgressStatus] = useState("In queue");
  const [error, setError] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successFileName, setSuccessFileName] = useState("");
  const [fileEntries, setFileEntries] = useState([]); // State to hold the selected files with metadata
  const [completedFiles, setCompletedFiles] = useState(0); // To track how many files have completed successfully
  const [processedFiles, setProcessedFiles] = useState(0); // To track total processed files (success + error)
  const [fileProgressMap, setFileProgressMap] = useState({}); // To track individual file progress
  const [fileStatusMap, setFileStatusMap] = useState({}); // Track status of each file
  const [fileErrorMap, setFileErrorMap] = useState({}); // Track errors for each file
  const [hasErrors, setHasErrors] = useState(false); // To track if any file has errors
  
  // Handlers for modals
  const handleUploadModal = () => {
    setUploadPopupVisible(!uploadPopupVisible);
    setError(null);
    setShowSuccess(false);
    setFileEntries([]); // Reset selected files when opening modal
    setProgress(0);
    setProgressStatus("In queue");
    setCompletedFiles(0);
    setProcessedFiles(0);
    setFileProgressMap({});
    setFileStatusMap({});
    setFileErrorMap({});
    setHasErrors(false);
  };
  
  const handleTemplateModal = () => {
    setTemplatePopupVisible(!templatePopupVisible);
  };

  // File Select and Upload Handlers
  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to an array
    const validFiles = files.filter(file => file.type === "application/pdf");

    if (validFiles.length === files.length && validFiles.length > 0) {
      const newFileEntries = validFiles.map(file => ({
        file,
        type: "SETUP INSTRUCTION", // Default type
        equipment: "GNSS", // Default equipment
        id: `file-${Date.now()}-${Math.random().toString(36).substr(2, 9)}` // Unique ID for tracking
      }));
      
      setFileEntries(prev => [...prev, ...newFileEntries]);
      setError(null);
    } else {
      setError("Invalid file type. Please upload PDF files only.");
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const validFiles = files.filter(file => file.type === "application/pdf");

    if (validFiles.length === files.length && validFiles.length > 0) {
      const newFileEntries = validFiles.map(file => ({
        file,
        type: "SETUP INSTRUCTION", // Default type
        equipment: "GNSS", // Default equipment
        id: `file-${Date.now()}-${Math.random().toString(36).substr(2, 9)}` // Unique ID for tracking
      }));
      
      setFileEntries(prev => [...prev, ...newFileEntries]);
      setError(null);
    } else {
      setError("Invalid file type. Please upload PDF files only.");
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  // Handle file type change
  const handleTypeChange = (id, value) => {
    setFileEntries(files => 
      files.map(file => 
        file.id === id ? { ...file, type: value } : file
      )
    );
  };

  // Handle equipment change
  const handleEquipmentChange = (id, value) => {
    setFileEntries(files => 
      files.map(file => 
        file.id === id ? { ...file, equipment: value } : file
      )
    );
  };

  // Remove file from the list
  const handleRemoveFile = (id) => {
    setFileEntries(files => files.filter(file => file.id !== id));
  };

  // Calculate actual progress percentage based on completed files
  const calculateOverallProgress = () => {
    if (fileEntries.length === 0) return 0;
    
    // Ensure we never exceed 100%
    return Math.min(
      Math.round((processedFiles / fileEntries.length) * 100),
      100
    );
  };

  // Update overall progress whenever the processedFiles state changes
  useEffect(() => {
    if (uploading) {
      setProgress(calculateOverallProgress());
    }
  }, [processedFiles, fileEntries.length, uploading]);

  // Effect to check if all files are processed and show success message
  useEffect(() => {
    if (fileEntries.length > 0 && processedFiles === fileEntries.length) {
      setUploading(false);
      if (!hasErrors) {
        setShowSuccess(true);
        setSuccessFileName("All files");
      }
    }
  }, [processedFiles, fileEntries.length, hasErrors]);

  const handleSubmit = async () => {
    if (fileEntries.length > 0) {
      setUploading(true);
      setError(null);
      setShowSuccess(false);
      setCompletedFiles(0); // Reset completed files counter
      setProcessedFiles(0); // Reset processed files counter
      setHasErrors(false); // Reset errors flag
      setProgress(0); // Reset overall progress
      
      // Initialize progress tracking for each file
      const initialProgressMap = {};
      const initialStatusMap = {}; // Initialize status map
      fileEntries.forEach(entry => {
        initialProgressMap[entry.file.name] = 0;
        initialStatusMap[entry.file.name] = "In queue"; // Initial status
      });
      setFileProgressMap(initialProgressMap);
      setFileStatusMap(initialStatusMap);
      setFileErrorMap({});
      
      // Prepare form data for multiple files
      const formData = new FormData();
      
      // Add each file to formData
      fileEntries.forEach(entry => {
        formData.append("files", entry.file);
      });
      
      // Add document types
      fileEntries.forEach(entry => {
        formData.append("type", entry.type);
      });
      
      // Add equipment types
      fileEntries.forEach(entry => {
        formData.append("equipment", entry.equipment);
      });
      
      try {
        // Use the new extract endpoint
        const vayuApi = `${process.env.REACT_APP_VAYU_API_HOST}/extract`;
        console.log("Uploading to:", vayuApi);
        const auth_token = localStorage.getItem("app_token");
        // Setup for event stream processing
        const response = await fetch(vayuApi, {
          method: 'POST',
          headers:{
            "Authorization": `${auth_token}`,
          },
          body: formData,
        });
        
        // Create a reader for the stream
        const reader = response.body.getReader();
        
        // Process the stream
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          
          // Parse the chunk into event data
          const text = new TextDecoder().decode(value);
          const events = text.split('\n').filter(line => line.trim());
          
          for (const event of events) {
            try {
              const data = JSON.parse(event);
              console.log("Progress update:", data);
              
              // Update current file being processed
              setSuccessFileName(data.file_name);
              
              // Check for error conditions - handle both formats the API might send
              // Either "progress": "ERROR" or "progress": "error"
              const isError = data.progress === "ERROR" || 
                             data.progress === "error" || 
                             (data.progress === "100%" && data.phase === "error");
              
              if (isError) {
                // Store the error message
                const errorMessage = `Error: ${data.phase}`;
                
                // Mark this file as failed
                setFileErrorMap(prev => ({
                  ...prev,
                  [data.file_name]: errorMessage
                }));
                
                // Update the file status
                setFileStatusMap(prev => ({
                  ...prev,
                  [data.file_name]: "Failed"
                }));
                
                // Set error flag
                setHasErrors(true);
                
                // Increment processed files counter (including errors)
                setProcessedFiles(prev => prev + 1);
                
                // Display error message for this file
                setError(`Error processing ${data.file_name}: ${data.phase}`);
                
                // Update current progress status text
                setProgressStatus("Error processing file");
                
              } else if ((data.progress === "100%" && data.phase === "complete") || 
                        (data.progress === "100%" && data.phase === "completed")) {
                // When a file completes successfully
                console.log("File completed:", data.file_name);
                
                // Increment completed files counter
                setCompletedFiles(prev => prev + 1);
                
                // Increment processed files counter
                setProcessedFiles(prev => prev + 1);
                
                // Update individual file progress to 100%
                setFileProgressMap(prev => ({
                  ...prev,
                  [data.file_name]: 100
                }));
                
                // Update file status
                setFileStatusMap(prev => ({
                  ...prev,
                  [data.file_name]: "Completed"
                }));
                
                // Update current status text for this file
                setProgressStatus("Completed");
              } else {
                // Regular progress update - normalize progress value
                let progressValue;
                
                if (data.progress === "100%") {
                  progressValue = 100;
                } else {
                  // Handle percentage with % sign or just number
                  progressValue = parseInt(data.progress.replace('%', '')) || 0;
                }
                
                // Update progress for individual file
                setFileProgressMap(prev => ({
                  ...prev,
                  [data.file_name]: progressValue
                }));
                
                // Update status text for this file with phase from API
                setFileStatusMap(prev => ({
                  ...prev,
                  [data.file_name]: data.phase
                }));
                
                // Update current file status text
                setProgressStatus(data.phase);
              }
            } catch (err) {
              console.error("Error parsing event:", err, event);
            }
          }
        }
      } catch (error) {
        console.error("Error uploading files:", error);
        setUploading(false);
        setProgress(0);
        setError("Error uploading files. Please try again.");
        setProgressStatus("Error...");
        setHasErrors(true);
      }
    } else {
      setError("Please select file(s).");
    }
  };

  useEffect(() => {
    props.resetProcedureDetails();
    props.resetStepDetails();
    props.setNewDocument(false);
  }, []);
  
  const onValueChanged = (event) => {
    setTemplate(event.target.value);
  };
  
  const handleTemplateSelection = (e) => {
    e.preventDefault();
    props.setNewDocument(true);
    props.setSelectedTemplate(template);
    props.history.push("/CreateInstructionsForm");
  };
  
  const ProgressStatus = ({ fileName }) => {
    const progress = fileProgressMap[fileName] || 0;
    const status = fileStatusMap[fileName] || "In queue";
    const hasError = fileErrorMap[fileName] !== undefined;
    
    let statusIcon;
    if (hasError) {
      statusIcon = <FaExclamationCircle color="red" size={20} />;
    } else if (progress === 100) {
      statusIcon = <FaCheckCircle color="green" size={20} />;
    } else if (progress === 0) {
      statusIcon = <FaHourglassHalf color="#f59e0b" size={20} />;
    } else {
      statusIcon = <FaHourglassHalf color="#f59e0b" size={20} />;
    }
    
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 4px",
          marginTop: "10px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {statusIcon}
          <span
            style={{ marginLeft: "10px", fontSize: "16px", color: hasError ? "red" : "#4a5568" }}
          >
            {hasError ? fileErrorMap[fileName] : status}
          </span>
        </div>
        <span style={{ fontSize: "14px", fontWeight: "500", color: "#2d3748" }}>
          {progress}%
        </span>
      </div>
    );
  };
  
  const SuccessMessage = ({ fileName }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        backgroundColor: "#f0fff4",
        border: "1px solid #48bb78",
        borderRadius: "4px",
        marginTop: "20px",
      }}
    >
      <FaCheckCircle
        color="#48bb78"
        size={20}
        style={{ marginRight: "10px" }}
      />
      <span style={{ color: "#2f855a", fontSize: "16px" }}>
        {fileName} {fileName === "All files" ? "have" : "has"} been successfully uploaded!
      </span>
    </div>
  );
  
  // Auto close modal after success
  useEffect(() => {
    if (showSuccess && !hasErrors) {
      const timer = setTimeout(() => {
        setProgress(0);
        setProgressStatus("In queue");
        setFileEntries([]);
        setCompletedFiles(0);
        setProcessedFiles(0);
        setFileProgressMap({});
        setFileStatusMap({});
        setFileErrorMap({});
        setShowSuccess(false);
      }, 3000); // Wait 3 seconds before closing
      
      return () => clearTimeout(timer);
    }
  }, [showSuccess, hasErrors]);
  
  const lookUpButtonFunc = () => {
    const redirectToExternalResource = (url) => window.open(url, "_blank");
    redirectToExternalResource(`${process.env.REACT_APP_VANOORD_URL}`);
  };
  
  return (
    <>
      <Card className="dashboard-card">
        <div className="dashboard-card-content">
          <div className="dashboard-card-image">
            <Image src={Technician} className="dashboard-card-image-holder" />
          </div>
          <div className="dashboard-card-messages">
            <h3>Hi, LexX here!</h3>
            <p className="label">What would you like to do?</p>
          </div>
          <div className="dashboard-card-buttons">
            <Button className="primaryButton" onClick={handleUploadModal}>
              Upload
            </Button>
            <Button className="secondaryButton" onClick={lookUpButtonFunc}>
              Look up Information
            </Button>
            <Button
              className="primaryButton"
              onClick={handleTemplateModal}
              disabled={props.isReviewer}
            >
              Create a New Document
            </Button>
          </div>
        </div>
      </Card>
      {/* Upload Modal */}
      <Modal
        style={{overflow: "auto"}}
        className="modal-upload"
        open={uploadPopupVisible}
        onClose={handleUploadModal}
        size="large"
      >
        <Modal.Header
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3>Ingest an artefact</h3>
          <Button icon="close" onClick={handleUploadModal} size="mini" />
        </Modal.Header>
        <Modal.Content>
          <p>Add your artefact here, and you can upload multiple files</p>
          <div
            className="dropzone"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            style={{
              border: "2px dashed #ccc",
              borderRadius: "5px",
              padding: "20px",
              textAlign: "center",
              cursor: "pointer",
              marginBottom: "10px",
            }}
          >
            <FaCloudUploadAlt size={40} color="#888" />
            <p>Drag your file(s) or <label htmlFor="file-upload" style={{cursor: "pointer", color: 'blue'}}>browse</label></p>
            <p>Max 100 MB files are allowed</p>
            <input
              id="file-upload"
              type="file"
              accept="application/pdf"
              multiple // Allow multiple file selection
              hidden
              onChange={handleFileSelect}
            />
          </div>
          <p>Supports .pdf files only</p>
          
          {fileEntries.length > 0 && (
            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              <h4>Selected Files:</h4>
              {fileEntries.map((entry) => (
                <div 
                  key={entry.id} 
                  style={{
                    border: "1px solid #e2e8f0",
                    borderRadius: "4px",
                    padding: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#f8fafc"
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
                    <span style={{ fontWeight: "500" }}>{entry.file.name}</span>
                    <Button 
                      icon 
                      size="mini" 
                      onClick={() => handleRemoveFile(entry.id)}
                      style={{ color: "red" }}
                    >
                      <FaTrash />
                    </Button>
                  </div>
                  
                  <Form>
                    <Form.Group widths="equal">
                      <Form.Field>
                        <label>Document Type</label>
                        <Dropdown
                          fluid
                          selection
                          options={documentTypeOptions}
                          value={entry.type}
                          onChange={(_, { value }) => handleTypeChange(entry.id, value)}
                        />
                      </Form.Field>
                      <Form.Field>
                        <label>Equipment</label>
                        <Dropdown
                          fluid
                          selection
                          options={equipmentOptions}
                          value={entry.equipment}
                          onChange={(_, { value }) => handleEquipmentChange(entry.id, value)}
                        />
                      </Form.Field>
                    </Form.Group>
                  </Form>
                </div>
              ))}
            </div>
          )}
          
          {error && (
            <div
              style={{
                color: "red",
                marginBottom: "10px",
                textAlign: "center",
              }}
            >
              {error}
            </div>
          )}
          
          {uploading && (
            <div style={{ marginTop: "20px" }}>
              <p>Overall Progress: {processedFiles}/{fileEntries.length} files processed ({progress}%)</p>
              <Progress 
                percent={progress} 
                active 
                progress 
                color={hasErrors ? "red" : "green"} 
              />
              
              <div style={{ marginTop: "15px" }}>
                <p>Currently Processing: {successFileName}</p>
                <ProgressStatus fileName={successFileName} />
              </div>
              
              {/* Display individual file progress */}
              {fileEntries.length > 0 && (
                <div style={{ marginTop: "20px" }}>
                  <h4>Individual File Progress:</h4>
                  {fileEntries.map(entry => {
                    const filename = entry.file.name;
                    const fileProgress = fileProgressMap[filename] || 0;
                    const hasError = fileErrorMap[filename] !== undefined;
                    const status = fileStatusMap[filename] || "In queue";
                    
                    return (
                      <div key={entry.id} style={{ marginBottom: "8px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <span>{filename}</span>
                          <span style={{ color: hasError ? "red" : "inherit" }}>
                            {fileProgress}% - {hasError ? "Failed" : status}
                          </span>
                        </div>
                        <Progress 
                          percent={fileProgress} 
                          size="tiny" 
                          color={hasError ? "red" : fileProgress === 100 ? "green" : "blue"} 
                          error={hasError}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
          
          {showSuccess && <SuccessMessage fileName={successFileName} />}
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={handleUploadModal}>Cancel</Button>
          <Button primary onClick={handleSubmit} disabled={fileEntries.length === 0 || uploading}>Submit</Button>
        </Modal.Actions>
      </Modal>
      
      {/* Template Selection Modal */}
      <Modal
        className="modal-upload"
        open={templatePopupVisible}
        onClose={handleTemplateModal}
      >
        <div className="modal-heading">
          <h3>Choose the Template</h3>
          <button onClick={handleTemplateModal}>X</button>
        </div>
        <div className="modal-action">
          <Modal.Actions className="modal-radio">
            <div className="modal-radio-group ">
              <div>
                <input
                  className="modal-radio-custom "
                  type="radio"
                  name=""
                  value={templateOptions[0].key}
                  checked={template === templateOptions[0].key}
                  onChange={(e) => onValueChanged(e)}
                />
                <label className="modal-radio-custom-label">
                  {templateOptions[0].value}
                </label>
              </div>
              <p
                style={{
                  fontSize: "11px",
                  fontStyle: "italic",
                  fontWeight: "400px",
                  color: "GrayText",
                  marginTop: "15px",
                  marginLeft: "26px",
                  marginRight: "10px",
                }}
              >
                A Setup Instruction outlines the steps to be followed in setting
                up a particular piece of equipment, software or system. The
                purpose of a Setup Instruction is to ensure that the equipment,
                software or system is set up correctly, according to established
                guidelines or specifications.
              </p>
            </div>
            <div className="modal-radio-group ">
              <div>
                <input
                  className="modal-radio-custom "
                  type="radio"
                  name=""
                  value={templateOptions[1].key}
                  checked={template === templateOptions[1].key}
                  onChange={(e) => onValueChanged(e)}
                />
                <label className="modal-radio-custom-label">
                  {templateOptions[1].value}
                </label>
              </div>
              <p
                style={{
                  fontSize: "11px",
                  fontStyle: "italic",
                  fontWeight: "400px",
                  color: "GrayText",
                  marginTop: "15px",
                  marginLeft: "26px",
                  marginRight: "10px",
                }}
              >
                An Operation Procedure outlines the steps to be followed in
                carrying out a particular process or task. The purpose of an
                Operation Procedure is to ensure that the process or task is
                carried out consistently and accurately, according to
                established guidelines or standards.
              </p>
            </div>
            <div>
              <Button
                className="modal-button-option"
                onClick={(e) => handleTemplateSelection(e)}
              >
                Choose
              </Button>
            </div>
          </Modal.Actions>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
  procedureDetails: state.procedureDetails,
  stepDetails: state.stepDetails,
  isReviewer: state.isReviewer,
});

export default withRouter(
  connect(mapStateToProps, {
    setSelectedTemplate,
    setProcedureDetails,
    resetProcedureDetails,
    resetStepDetails,
    saveIsReviewer,
    setNewDocument,
  })(LexxDashboardCard)
);